module controllers {
    export module lc {
        interface ILetterOfCreditUpdateScope extends ng.IScope {
        }

        interface ILetterOfCreditUpdateScopeParams extends ng.ui.IStateParamsService {
            lcId: number;
        }

        export class letterOfCreditUpdateCtrl {

            static $inject = [
                "$scope",
                "$rootScope",
                "generalService",
                "$q",
                "$anchorScroll",
                "$uibModal",
                "bsLoadingOverlayService",
                "$stateParams",
                "entityService",
                "$state",
                "$timeout",
                "$transitions",
                "documentRepositoryService",
                "letterOfCreditService",
                "bankService",
                "statusService",
                "currencyService",
                "supplierService",
                "classificationValueService",
                "termsOfPaymentService",
                "incotermService",
                "paymentMethodService",
                "termsOfDeliveryService",
                "userAccountService",
                "countryService",
                "unLocationCodeService",
                "letterOfCreditLineService",
                "templateService",
                "documentTypeService",
            ];

            dropdownsObject = {
                TemplateList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            lcId: number;
            letterOfCredit: interfaces.lc.ILetterOfCredit;
            letterOfCreditLine: interfaces.lc.ILetterOfCreditLine;
            public locStatus: interfaces.master.IStatusData;
            documentRepository: interfaces.documentManagement.IDocumentRepository;
            documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            statusList: interfaces.applicationcore.IDropdownModel[] = [];
            termsOfDeliveryList: Array<interfaces.applicationcore.IDropdownModel>;
            bankChargesList: Array<interfaces.applicationcore.IDropdownModel>;
            breadCrumbDesc: string;
            selectedTab: number = 0;
            addendumHTML: string = "";

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            yesNoItems = [{
                id: null,
                label: 'Please Select',
            }, {
                id: true,
                label: 'Yes',
            }, {
                id: false,
                label: 'No',
            }];

            ownerEntityId: number;
            apiLCDetailList: uiGrid.IGridApi;
            apiAmendmentList: uiGrid.IGridApi;
            apiPOList: uiGrid.IGridApi;
            selectedPO: uiGrid.IGridRow;

            letterOfCreditHook: any;
            purchaseOrderHook: any;

            constructor(
                private $scope: ILetterOfCreditUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ILetterOfCreditUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public letterOfCreditService: services.lc.letterOfCreditService,
                public bankService: interfaces.master.IBankService,
                private statusService: interfaces.master.IStatusService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private supplierService: interfaces.master.ISupplierService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public termsOfPaymentService: services.master.termsOfPaymentService,
                public incotermService: services.applicationcore.incotermService,
                public paymentMethodService: services.master.paymentMethodService,
                public termsOfDeliveryService: services.master.termsOfDeliveryService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private countryService: interfaces.applicationcore.ICountryService,
                private unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService,
                public letterOfCreditLineService: services.lc.letterOfCreditLineService,
                public templateService: services.reporting.templateService,
                private documentTypeService: interfaces.documentManagement.IDocumentTypeService,
            ) {

                this.lcId = $stateParams.lcId;

              /*  this.purchaseOrderHook = $transitions.onSuccess({
                    to: 'auth.LetterOfCredit.Update',
                    from: 'auth.LetterOfCredit.Update.PurchaseOrder'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'letterOfCredit.update'
                    }, () => {

                        return this.RefreshFromPurchaseOrder();
                    });
                });
                */

                this.letterOfCreditHook = $transitions.onSuccess({
                    to: 'auth.LetterOfCredit.Update',
                    from: 'auth.LetterOfCredit.Update.**'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'letterOfCredit.update'
                    }, () => {

                            return this.loadLetterOfCredit();
                    });
                });

                var loadPromises: ng.IPromise<any>[] = [];
                loadPromises.push(this.loadLetterOfCredit());
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                },
                    () => {                        
                        return $q.all(loadPromises);
                    });
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiLCDetailList = gridApi;
            }

            registerAmendmentGridApi(gridApi: uiGrid.IGridApi) {
                this.apiAmendmentList = gridApi;
            }

            loadTemplateList(typeId: number) {
                return this.templateService.getDropdownListByType(this.letterOfCredit.EntityId, typeId).query({
                }, (resultList) => {
                }).$promise;
            }


            //Build UIGrid
            gvwDetails: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.letterOfCreditUpdateCtrl.lcLine(row.entity.Id, grid.appScope.letterOfCreditUpdateCtrl.letterOfCredit.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                    {
                    name: "EDITPO",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div>
                        <button type="button" ng-click="grid.appScope.letterOfCreditUpdateCtrl.GoToPurchaseOrder(row.entity.pdiId)" class="btn btn-primary btn-sm" ng-disabled="!row.entity.pdiId>0">
                            Edit PO
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 60,
                    visible: true
                },
                {
                    name: "ReferenceNumber",
                    displayName: "Reference Number",
                    field: "ReferenceNumber",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "ProductDescription",
                    displayName: "Product Description",
                    field: "ProductDescription",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "OrderedQuantity",
                    displayName: "Ordered Quantity",
                    field: "OrderedQuantity",
                    width: 130,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "OrderUnitOfMeasure",
                    displayName: "Order Unit Of Measure",
                    field: "OrderUnitOfMeasure.Display",
                    width: 200,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "SupplierPrice",
                    displayName: "Order Cost Price",
                    field: "SupplierPrice",
                    cellFilter: "number: 2",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }]
            };

            gvwAmendments: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerAmendmentGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "Accept",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div>
                        <button type="button" ng-click="grid.appScope.letterOfCreditUpdateCtrl.lcAmendmentComments(row.entity.id)" class="btn btn-default btn-sm">
                            Accept
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 55,
                    visible: true
                },
                {
                    name: "WillModify",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div>
                        <button type="button" ng-click="grid.appScope.letterOfCreditUpdateCtrl.lcAmendmentComments(row.entity.pdiId)" class="btn btn-default btn-sm">
                            Will Modify
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 75,
                    visible: true
                },
                {
                    name: "Description",
                    displayName: "Description",
                    field: "Description",
                    width: 120,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "ActionedDate",
                    displayName: "Actioned Date",
                    field: "ActionStampDate",
                    width: 120,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "ActionedBy",
                    displayName: "Actioned By",
                    field: "ActionBy",
                    width: 130,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "ActionTaken",
                    displayName: "Action Taken",
                    field: "ActionTaken",
                    width: 100,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "AmendmentComments",
                    displayName: "Amendment Comments",
                    field: "Comments",
                    width: 350,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }]
            };
            lcAmendmentComments(amendmentId: number) {
                //SPACEHOLDER
                //For when Anton looks at the Amendments functionality.
            }
            
            registerPOGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPOList = gridApi;
            }

            gvwPOList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: false,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.registerPOGridApi(gridApi);

                    this.apiPOList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiPOList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                        name: "EntityCode",
                        displayName: "Owner Entity",
                        field: "POOwnerEntity",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "PONumber",
                        displayName: "PO Number",
                        field: "PONumber",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                        name: "PODate",
                        displayName: "PO Date",
                        field: "PODate",
                        width: 150,
                        type: "date",
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="PODate"></gts-date>
                                        
                            </div>`,
                }, {
                        name: "POTypeDescription",
                        displayName: "PO Type Description",
                        field: "POTypeDescription",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                        name: "Incoterm",
                        displayName: "Incoterm",
                        field: "IncotermCode",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "TermsOfPayment",
                        displayName: "Terms of Payment",
                        field: "TermsOfPaymentDescription",
                        type: "number",
                        width: 140,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
                }, {
                        name: "Status",
                        displayName: "Status",
                        field: "StatusDescription",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "TotalForeignCurrencyValue",
                        displayName: "Total Foreign Currency Value",
                        field: "TotalForeignCurrencyValue",
                        width: 200,
                        cellClass: 'text-right',
                        cellFilter: "number: 2",
                        enableFiltering: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "CurrencyCode",
                        displayName: "Currency",
                        field: "CurrencyCode",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                        name: "TotalOrderQuantity",
                        displayName: "Total Order Quantity",
                        field: "TotalOrderQuantity",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }]
            };
            lcLine_click() {
                this.lcLine(0, this.letterOfCredit.Id)
            }
            lcLine(llnId: number, lcId: number)
            {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Letter of Credit Detail</h3>
                            </div>
                            <div bs-loading-overlay bs-loading-overlay-reference-id="lclines.update">
                                <form name="lcLinesUpdate">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Reference Number</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input class="form-control" type="text" ng-model="lcLineCtrl.letterOfCreditLine.ReferenceNumber" name="ReferenceNumber" aria-label="ReferenceNumber" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Product Description</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input class="form-control" type="text" ng-model="lcLineCtrl.letterOfCreditLine.ProductDescription" name="ProductDescription" aria-label="ProductDescription" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Ordered Quantity</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input class="form-control" type="text" ng-model="lcLineCtrl.letterOfCreditLine.OrderedQuantity" ng-change="lcLineCtrl.recalculateValues()" name="OrderedQuantity" aria-label="OrderedQuantity" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>Order Unit of Measure</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <gts-dropdown name="UnitOfMeasure" ng-model="lcLineCtrl.letterOfCreditLine.OrderUnitOfMeasure" load-data="lcLineCtrl.loadUoMs()" required="false"></gts-dropdown>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Order Cost Price</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input class="form-control" type="text" step="0.01" custom-format="number" ng-model="lcLineCtrl.letterOfCreditLine.SupplierPrice" ng-change="lcLineCtrl.recalculateValues()" name="SupplierPrice" aria-label="Amount" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Order Cost Value</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input class="form-control" type="text" step="0.01" custom-format="number" ng-model="lcLineCtrl.letterOfCreditLine.SuplierValue" name="Amount" aria-label="SuplierValue" />
                                                            </p>
                                                        </div>
                                                        <div class="col-md-1">
                                                            {{lcLineCtrl.letterOfCreditLine.CurrencyCode}}
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>Product Document Type</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <gts-dropdown name="productDocument" ng-model="lcLineCtrl.letterOfCreditLine.ProductDocument" load-data="lcLineCtrl.loadProductDocuments()" required="false"></gts-dropdown>

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>Document Numbers</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input class="form-control" type="text" ng-model="lcLineCtrl.letterOfCreditLine.ProductDocumentNumbers" name="ProductDocumentNumbers" aria-label="Amount" />
                                                            </p>

                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>Document Date</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <gts-date format="yyyy/MM/dd" ng-model="lcLineCtrl.letterOfCreditLine.ProductDocumentDate" name="ProductDocumentDate">
                                                                </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>Shipping By Date</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <gts-date format="yyyy/MM/dd" ng-model="lcLineCtrl.letterOfCreditLine.ShippedByDate" name="ShippedByDate">
                                                                </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>Revised Ship By Date</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <gts-date format="yyyy/MM/dd" ng-model="lcLineCtrl.letterOfCreditLine.RevisedShipByDate" name="RevisedShipByDate">
                                                                </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <opt></opt>
                                                            <label>SWIFT Product Description</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm has-feedback">
                                                                <textarea rows="3" name="ProductDescriptionSwift" ng-model="lcLineCtrl.letterOfCreditLine.ProductDescriptionSwift"
                                                                          class="form-control"></textarea>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" type="button" ng-click="lcLineCtrl.save()">OK</button>
                                            <button class="btn btn-info" type="button" ng-click="lcLineCtrl.cancel()">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        `,
                    controller: class lcLineCtrl {
                        messages: interfaces.applicationcore.IMessage[];
                        letterOfCreditLine: interfaces.lc.ILetterOfCreditLine;                        
                        constructor(
                            $scope,
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public letterOfCreditLineService: services.lc.letterOfCreditLineService,
                            private bsLoadingOverlayService,
                            private $q: ng.IQService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private documentTypeService: interfaces.documentManagement.IDocumentTypeService,
                            private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                        ) {                          

                            var loadPromises: ng.IPromise<any>[] = [];

                            loadPromises.push(this.loadLetterOfCreditLine(llnId, lcId));
                            
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'lclines.update'
                            },
                                () => {
                                    return $q.all(loadPromises);
                                });                           
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        recalculateValues() {
                            this.letterOfCreditLine.SuplierValue = this.letterOfCreditLine.SupplierPrice * this.letterOfCreditLine.OrderedQuantity;
                        }

                        SaveLetterOfCredit(): ng.IPromise<boolean> {
                            var deferre = this.$q.defer<boolean>();

                            this.letterOfCreditLineService.save().save(this.letterOfCreditLine, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);

                                llnId = Number(data.ID);

                                deferre.resolve(true);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                deferre.resolve(false);
                            });

                            return deferre.promise;
                        };

                        save() {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'letterOfCredit.update'
                            },
                                () => {
                                    return this.SaveLetterOfCredit().then((data: boolean) => {
                                        if (data) {
                                            this.$uibModalInstance.close(true);
                                        }
                                    });
                                });

                        }

                        loadProductDocuments() {
                            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                classificationId: Enum.ClassificationEnum.ProductDocumentType
                            }, (resultList) => {
                            }).$promise;
                        }

                        loadUoMs() {
                            return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.$rootScope.Entity.Id }, () => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        };

                        loadUnitOfmeasures() {
                            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                classificationId: Enum.ClassificationEnum.VolumeUnitOfMeasure
                            }, (resultList) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }

                        loadLetterOfCreditLine(letterOfCreditLineId: number, letterOfCreditId: number) {
                            return this.letterOfCreditLineService.getLetterOfCreditLineDetail(letterOfCreditLineId, letterOfCreditId).query((data: interfaces.lc.ILetterOfCreditLine) => {
                                this.letterOfCreditLine = data;
                            }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }                        

                    },
                    controllerAs: "lcLineCtrl",
                    resolve: {
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadLetterOfCredit();
                    }
                });
            }
            loadLetterOfCreditLine(Id: number) {
                return this.letterOfCreditLineService.getLetterOfCreditLines(Id).query((data: interfaces.lc.ILetterOfCreditLine) => {
                    this.letterOfCreditLine = data;
                   
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }
            loadLetterOfCredit() {
                return this.letterOfCreditService.getLetterOfCredit(this.lcId).query((data: interfaces.lc.ILetterOfCredit) => {                    
                    this.letterOfCredit = data;
                    this.breadCrumbDesc = this.letterOfCredit.Code;
                    this.loadBankChargesTypes();
                    this.loadStatus();
                    this.getDocuments(this.showAll);
                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwDetails.data = this.letterOfCredit.LetterOfCreditLineList;
                        this.gvwAmendments.data = this.letterOfCredit.AmendmentsList;
                        this.gvwPOList.data = this.letterOfCredit.LetterOfCreditPurchaseOrderList;
                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }
            generateAddendumText() {
                var defer = this.$q.defer();

                this.SaveLetterOfCredit().then(() => {
                    this.letterOfCreditService.generateAddendum(this.letterOfCredit.Id).save((result: interfaces.applicationcore.IMessageHandler) => {
                        defer.resolve();
                        this.generalService.displayMessageHandler(result);

                    }, (failureData) => {
                        defer.reject();
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }, () => {
                    defer.reject();
                });

                return defer.promise;
            }
            generateAddendumText_click() {                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                }, () => {
                    this.SaveLetterOfCredit().then(() => {
                        return this.generateAddendumText().then(() => {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'letterOfCredit.update'
                                }, () => {
                                    return this.loadLetterOfCredit();
                                });
                            });
                        });
                    }, () => {
                        return;
                    });
                });
            }

            generateAmendmentAddendumText() {
                var defer = this.$q.defer();

                this.SaveLetterOfCredit().then(() => {
                    this.letterOfCreditService.generateAmendmentAddendum(this.letterOfCredit.Id).save((result: interfaces.applicationcore.IMessageHandler) => {
                        defer.resolve();
                        this.generalService.displayMessageHandler(result);

                    }, (failureData) => {
                        defer.reject();
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }, () => {
                    defer.reject();
                });

                return defer.promise;
            }
            generateAmendmentAddendumText_click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                }, () => {
                    this.SaveLetterOfCredit().then(() => {
                        return this.generateAmendmentAddendumText().then(() => {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'letterOfCredit.update'
                                }, () => {
                                    return this.loadLetterOfCredit();
                                });
                            });
                        });
                    }, () => {
                        return;
                    });
                });
            }
            clearPorts() {
                if (this.letterOfCredit.DispatchFromLocationAllowAllPorts == true) {
                    this.letterOfCredit.DispatchFromLocation = null;
                }
                if (this.letterOfCredit.LoadingPortAllowAllPorts == true) {
                    this.letterOfCredit.LoadingPortLocation = null;
                }
                if (this.letterOfCredit.DischargePortAllowAllPorts == true) {
                    this.letterOfCredit.DischargePortLocation = null;
                }
                if (this.letterOfCredit.FinalDestinationLocationAllowAllPorts == true) {
                    this.letterOfCredit.FinalDestinationLocation = null;
                }
            }
            RefreshPurchaseOrders() {  
                this.$state.go("auth.LetterOfCredit.Update.PurchaseOrder", { ownerEntityId: this.letterOfCredit.EntityId, supplierId: this.letterOfCredit.Supplier.Id, currencyId: this.letterOfCredit.Currency.Id, creditTypeId: this.letterOfCredit.CreditType.Id, incotermId: this.letterOfCredit.Incoterm.Id , locId: this.letterOfCredit.Id});                
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {
                this.$state.go("auth.LetterOfCredit.Update.Documents");

            }

            loadBanks() {
                return this.bankService.getDropdownList(this.$rootScope.Entity.Id, "").query({
                }, (resultList) => {
                }).$promise;
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            loadStatuses() {
                return this.statusService.getStatusDropdownList().query({ type: Enum.EnumStatusType.LetterOfCredit }, (result) => {
                    this.statusList = result;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadSuppliers(searchText: string) {
                return this.supplierService.getSupplierDropdown(this.$rootScope.Entity.Id, searchText).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadCreditTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CreditType
                }, (resultList) => {
                }).$promise;
            }

            loadTermsOfPayment(searchText: string) {
                return this.termsOfPaymentService.getTermsOfPaymentDropdown(this.letterOfCredit.EntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }
            loadOriginLocations(searchText: string) {
                if (this.letterOfCredit && this.letterOfCredit.OriginCountry && this.letterOfCredit.OriginCountry.Id > 0) {
                    return this.unLocationCodeService.getDropdownList().query({
                        countryId: this.letterOfCredit.OriginCountry.Id,
                        searchText: searchText
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                };
            }
            loadDestinationLocations(searchText: string) {
                if (this.letterOfCredit && this.letterOfCredit.DestinationCountry && this.letterOfCredit.DestinationCountry.Id > 0) {
                    return this.unLocationCodeService.getDropdownList().query({
                        countryId: this.letterOfCredit.DestinationCountry.Id,
                        searchText: searchText
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                };
            }
            loadExpirationLocations(searchText: string) {
                if (this.letterOfCredit && this.letterOfCredit.ExpirationLocationCountry && this.letterOfCredit.ExpirationLocationCountry.Id > 0) {
                    return this.unLocationCodeService.getDropdownList().query({
                        countryId: this.letterOfCredit.ExpirationLocationCountry.Id,
                        searchText: searchText
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                };
            }

            LatestShipmentDateChanged(newValue: moment.Moment){
                if (this.letterOfCredit.LatestShipmentDate && this.letterOfCredit.DocumentPresentationPeriod)
                    this.letterOfCredit.ExpirationDate =  newValue.clone().add(this.letterOfCredit.DocumentPresentationPeriod as number, 'day');
            }

            incoTermChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.letterOfCredit.TermsOfDelivery = null;
                if (model) {
                    this.termsOfDeliveryList = [];
                    this.loadTermsOfDeliveries();
                }
            }
            loadTermsOfPaymentDateTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.PaymentDueDateType
                }, (resultList) => {
                }).$promise;
            }
            loadToleranceDirections() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.AmountToleranceDirection
                }, (resultList) => {
                }).$promise;
            }
            loadConfirmationTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.LetterOfCreditConfirmation
                }, (resultList) => {
                }).$promise;
            }
            loadUsers(searchText: string) {
                if (this.letterOfCredit) {
                    return this.userAccountService.getDropdownList(searchText, this.letterOfCredit.EntityId).query(
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadTermsOfPaymentTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.LetterOfCreditPaymentTerms
                }, (resultList) => {
                }).$promise;
            }

            loadTermsOfDeliveries() {
                var defered = this.$q.defer();

                if (!this.letterOfCredit || !this.letterOfCredit.Incoterm) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.letterOfCredit.EntityId, incoTermId: this.letterOfCredit.Incoterm.Id }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                    this.termsOfDeliveryList = list;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadIncoterms() {
                return this.incotermService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            loadIncotermTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.IncotermType
                }, (resultList) => {
                }).$promise;
            }

            RefreshBankingDetails_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                }, () => {
                    return this.RefreshBankingDetails().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'letterOfCredit.update'
                                }, () => {
                                        return this.loadLetterOfCredit();
                                });
                            });
                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            }
            RefreshFromPurchaseOrder() {
                return this.letterOfCreditService.refreshFromPurchaseOrder().save({
                    lcId: this.letterOfCredit.Id,
                    entityId: this.letterOfCredit.EntityId
                }).$promise;
            };
            refreshFromPurchaseOrder_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                }, () => {
                        return this.RefreshFromPurchaseOrder().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'letterOfCredit.update'
                                }, () => {
                                    return this.loadLetterOfCredit();
                                });
                            });
                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            }

            loadTransportDocumentTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.TransportDocumentType
                }, (resultList) => {
                }).$promise;
            }
            loadInsuranceParties() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.InsuranceParty
                }, (resultList) => {
                }).$promise;
            }
            loadIsInsuranceEffected() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.InsuranceIsEffected
                }, (resultList) => {
                }).$promise;
            }
            loadCoOIssuers() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CertificateOfOriginIssuer
                }, (resultList) => {
                }).$promise;
            }
            RefreshBankingDetails() {
                return this.letterOfCreditService.refreshBankingDetails().save({
                    letterOfCreditId: this.letterOfCredit.Id
                }).$promise;
            };
            loadProductDocuments() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ProductDocumentType
                }, (resultList) => {
                }).$promise;
            }
            loadBankChargesTypes() {
                this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.BankCharges
                }, (resultList) => {
                    this.bankChargesList = resultList;
                });
            }
            loadDocumentDeliveryMethods() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.DocumentDeliveryMethod
                }, (resultList) => {
                }).$promise;
            }

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.letterOfCredit.Code, systemType: Enum.EnumSystemType.LetterOfCredit, ownerEntityId: this.letterOfCredit.EntityId,showall:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                    if (this.documentRepository)
                        this.loadDocumentTypes();

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }



            loadDocumentTypes() {

                return this.documentTypeService.getDocumentTypes().query({
                    repTypeId: this.documentRepository.RepositoryTypeId,
                    ownerEntityId: this.documentRepository.OwnerEntityId,
                    referenceNumber: this.letterOfCredit.Code
                }, (documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    this.documentTypes = documentTypes;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadStatus() {
                this.locStatus = {
                    StatusName: this.letterOfCredit.StatusName,
                    Id: this.letterOfCredit.Id,
                    EntityId: this.letterOfCredit.EntityId,
                    CurrentStatusId: this.letterOfCredit.StatusId,
                    CurrentStatusComments: this.letterOfCredit.StatusLastComments,
                    CurrentStatusStampDate: this.letterOfCredit.StatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.letterOfCredit.StatusLastUpdateStampFullName,
                    CurrentStatusType: Enum.EnumStatusType.LetterOfCredit,
                    EnumStatusType: Enum.EnumStatusType.LetterOfCredit,
                    EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.LetterOfCredit,
                    IsEditable: true
                };
            }
            updateStatus = (newStatusId: number, comments: string) => {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.letterOfCredit.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                },
                    () => {
                        this.SaveLetterOfCredit().then(() => {
                            this.letterOfCreditService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'letterOfCredit.update'
                                }, () => {
                                    this.selectedTab = 8;
                                    return this.loadLetterOfCredit();
                                });

                                this.generalService.displayMessageHandler(data);
                            }, (failureData) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'letterOfCredit.update'
                                }, () => {
                                    this.selectedTab = 8;
                                        return this.loadLetterOfCredit();
                                });

                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            });
                        }, () => {
                            return;
                        });
                    });
            };

            createNewVersion_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Create new version?").then((result: boolean) => {
                    if (result) {
                        this.createNewVersion(this.letterOfCredit.Id, 4, "Create New Version");
                    }
                });
            }

            createNewVersion(id: number, functionClassificationValueId: number, reason: string) {
                this.letterOfCreditService.CreateNewVersion().save(
                    { id, functionClassificationValueId, reason },
                    (result) => {
                        this.generalService.displayMessageHandler(result);
                        //Result
                        this.loadLetterOfCredit();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
            }

            GoToPurchaseOrder = (poId: number) => {
                var url = '#!/PurchaseOrders/Update/' + poId;
                window.open(url, '_blank');
            }
            
            SaveLetterOfCredit(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.letterOfCreditService.save().save(this.letterOfCredit, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.lcId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                },
                    () => {
                        return this.SaveLetterOfCredit().then((data: boolean) => {
                            if (data) {
                                this.loadLetterOfCredit();
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.LetterOfCredit.Update", { lcId: this.lcId });
                            }
                        });
                    });

            }
            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                }, () => {
                        return this.SaveLetterOfCredit().then(() => {
                        this.Close();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");
                    });
                });
            }
            SaveClose2() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'letterOfCredit.update'
                },
                    () => {
                        return this.SaveLetterOfCredit().then((data: boolean) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("letterOfCreditUpdateCtrl", controllers.lc.letterOfCreditUpdateCtrl);
    }
}
